import React from "react"
import HeroSvg from "../components/heroSvg"
import "./style.css"

export default function HeroPages({ title = "", text = "", image }) {
  return (
    <section className="hero-bg bg-orange-100 pt-20 pb-16 mb-10 relative">
      <div className="grid md:grid-cols-2 gap-10 container mx-auto px-5">
        <div className="relative block md:hidden h-48">
          <div className="bg-primary absolute mt-12 top-0 right-0 h-full w-full shadow-2xl overflow-hidden rounded-lg z-10">
            {image}
          </div>
        </div>

        <div className="text-primary pt-10 pb-5 md:pb-10 md:pt-20">
          <h1 className="text-6xl leading-none font-serif">{title}</h1>
          <p className="text-xl mb-5 mt-2 text-blue-900 leading-relaxed">
            {text}
          </p>
        </div>
        <div className="relative hidden md:block">
          <div className="bg-primary absolute mt-5 top-0 right-0 h-full w-full shadow-2xl overflow-hidden rounded-lg z-10">
            {image}
          </div>
        </div>
        <HeroSvg className="absolute left-0 bottom-0" />
      </div>
    </section>
  )
}
